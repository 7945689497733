import { graphql } from "gatsby"
import React from "react"
import WelcomeBack from "../../components/account/pages/welcome-back"

const WelcomeBackPage = ({ data }) => {
  return <WelcomeBack data={data} />
}

export default WelcomeBackPage

export const pageQuery = graphql`
  {
    tileSection: contentfulTileSectionOld(
      title: { eq: "welcome-back-page" }
      node_locale: { eq: "en-US" }
    ) {
      ...TileSection
    }
  }
`
